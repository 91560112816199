import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Link from "../components/LinkWithTransitionLock"
import Helmet from "react-helmet"
import InViewObserver from "../components/InViewObserver"
import PageContentPath from "../components/PageContentPath"

import { isMobile } from "react-device-detect"
import { Expo, gsap } from 'gsap';

class Page extends PureComponent {
  constructor(props) {
     super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {

    var next_visual = require('../images/home/project_d.png');

    return (

      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">駐村編輯</h2>
          <h1 className="main_title">走讀/藝術共創工作坊</h1>
          <h6 className="speaker">策展人/林書豪</h6>
        </div>

        <div className="page_content">

          <section className="row_area_s project_c_4_a">
            <div className="column_5">
              <div className="context">
                <p>初期完成萬華在地的資源盤點了解後，安排「走讀、藝術共創工作坊 」各三場活動，邀請三位在地工作者和青年藝術家等角色，以萬華車站作為中心，依各走讀主題內容進行三條導覽路徑：文史、食物、藝術；各場走讀活動結束後，接者舉辦三場「藝術共創工作坊」試者帶領參與走讀後的民眾，透過藝術創作共同對於所聆聽的內容進行心得回饋和發揮想像力，三場工作坊共完成三組系列作品，並在線上展覽呈現成果與大家分享，引發更多人對於家與生活的想像。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_a_2 project_c_4_head row_gallery">
            <div className="column_7 row_area_s gallery_wrap">
              <figure className="figure size_7 main_map">
                <img src={require("../images/project_c/c_1/project_c_1_5.jpg")} alt=""/>
                {/* <figcaption className="figcaption left">Assemble 團隊照（右）｜ Turner Prize Exhibition 2015（左）<br/>圖片提供/Courtesy of Assemble</figcaption> */}
              </figure>
              <figure className="figure onset_left_6 offset_right_1 size_m">
                <img src={require("../images/project_c/c_4_2/project_c_4_2_1_s.jpg")} alt=""/>
              </figure>
            </div>
          </section>

          <section className="row_area_s project_c_4_a_3">
            <div className="column_5 onset_left_1">
              <div className="context">
                <p>第一，走讀，邀請<strong>在地社區工作者-陳德君</strong>、進駐<strong>新富町文化市場的藝術家-陳冠蓉</strong>、<strong>萬華社區小學-魏子鈞</strong>，三場走訪主題分別為是「萬華文史、食物記憶、社區藝術」從認識萬華地方文史開始，看見火車鐵路發展所留傳的石敢當，成為當地自主管理的社區信仰空間；並走在弄巷中品嚐在地生活飲食，品嚐家吶子台式居酒屋的創意料理，也感受到店家與社區共好，串連周邊發行在地刊物《東園誌》；最後，回顧萬華社區小學所策劃的「2019堀江町藝術季」在鄰里巷道中，策展團隊活用社區公佈欄，展示民眾合力完成的版畫故事作品，了解社區空間、民眾參與和藝術策展之關係。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_a_4">
            <div className="column_5 onset_left_1">
              <div className="context">
                <p className="">第二，藝術共創工作坊，各場走讀後分別進行「地方觀察探索、生活飲食故事、社區藝術想像」共三場工作坊，第一場從萬華文史走讀到地方觀察探索工作坊，以「街區生活、青年創業、節慶信仰」以手寫 小卡紀錄大家對於萬華所觀察到的空間與故事；第二場從食物記憶走讀到生活飲食故事工作坊，透過文字書寫感受當地飲食並回想生命中的味覺記憶；第三場從社區藝術走讀到社區藝術想像工作坊，運用走讀現場的攝影照片，剪黏拼貼成影像作品，想像未來社區與藝術的願景畫面。</p>
                <p className="onset_right_2">初步完成駐村編輯所提出「藝術共創(Art Co-creation)」概念， 包含：藝術家進駐、藝術共創工作坊、藝術策展等操作方法，期待歡迎更多城市裡的夥伴加入，創造社宅公共藝術的自主良善循環社群合作關係。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_b row_break">
            <div className="column_3">
              <div className="context">
                <h3>在地走讀地圖與行程</h3>
                <p>找尋在地關鍵人物，訪談並邀請其建立自己生活的走讀地圖，對外舉辦走讀活動讓在地記憶得以被紀錄並且成為能量。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_c project_c_4_item">
            <div className="column_7 gallery_wrap">
              <Link to='/project_c_4_1' className="project_cover">
                <figure className="figure project_item onset_left_5 size_l">
                  <img src={require("../images/project_c/c_4_1/project_c_4_1_3.jpg")} alt="萬華文史走讀"/>
                </figure>
                <figure className="figure project_item size_full">
                  <div className="project_cover">
                    <img src={require("../images/project_c/c_4_1/project_c_4_1_5.jpg")} alt="萬華文史走讀"/>
                    <figcaption className="figcaption main center in"><div className="text_inner">萬華文史走讀</div></figcaption>
                  </div>
                </figure>
              </Link>
            </div>
          </section>


          <section className="row_area_s project_c_4_d project_c_4_item">
            <div className="column_7 gallery_wrap">
              <Link to='/project_c_4_2' className="project_cover">
                <figure className="figure project_item size_l">
                  <img src={require("../images/project_c/c_4_2/project_c_4_2_1.jpg")} alt="食物記憶走讀"/>
                </figure>
                <figure className="figure project_item onset_left_1 size_full">
                  <img src={require("../images/project_c/c_4_2/project_c_4_2_6.jpg")} alt="食物記憶走讀"/>
                  <figcaption className="figcaption main center in"><div className="text_inner">食物記憶走讀</div></figcaption>
                </figure>
              </Link>
            </div>
          </section>

          <section className="row_area_s project_c_4_e project_c_4_item">
            <div className="column_7 gallery_wrap">
              <Link to='/project_c_4_3' className="project_cover">
                <figure className="figure project_item onset_left_5 size_l">
                  <img src={require("../images/project_c/c_4_3/project_c_4_3_1.jpg")} alt="社區藝術走讀"/>
                </figure>
                <figure className="figure project_item size_full">
                  <img src={require("../images/project_c/c_4_3/project_c_4_3_6.jpg")} alt="社區藝術走讀"/>
                  <figcaption className="figcaption main center in"><div className="text_inner">社區藝術走讀</div></figcaption>
                </figure>
              </Link>
            </div>
          </section>

          {!isMobile ? <PageContentPath /> : null}

        </div>

        <div className="page_footer">
          <Link to="/project_d" className="next_page">
            <div className="next_title">養一隻植物和你說說話</div>
            <div className="next_owner">策展人/賴映如</div>
            <div className="next_visual" style={ { backgroundImage: "url(" + next_visual + ")" } }></div>
          </Link>
        </div>

      </div>

    );
  }

}


export default Page
